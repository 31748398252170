// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@popperjs/core")
const feather = require('feather-icons')
import "bootstrap"
// import 'bootstrap-icons/font/bootstrap-icons.css'
import Typed from "typed.js"
import HSCore from "../src/front-template/hs.core"
import HSMegaMenu from "../src/front-template/vendor/hs-mega-menu.min"
import HSHeader from "../src/front-template/vendor/hs-header/hs-header"
import "../src/front-template/hs.tom-select"
import HSGoTo from "../src/front-template/vendor/hs-go-to/hs-go-to"
//import "../src/front-template/hs.typed"
import "../src/cookieconsent"

// Import the Bootstrap specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

const initTypedComponents = function () {
	var components = document.getElementsByClassName("js-typedjs");
	for (var i = 0; i < components.length; i++) {
	  var options = JSON.parse(components[i].dataset.hsTypedOptions)
		var typed = new Typed('.js-typedjs', options);
	}
}

const initTooltips = function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
}

const initAnchorLinks = function () {
  var anchors = document.getElementsByClassName("anchor-link");
  for (var i = 0; i < anchors.length; i++) {
    anchors[i].addEventListener("click", (event) => {
      event.preventDefault();
      const el = document.querySelector(event.currentTarget.hash);
      const topPos = el.getBoundingClientRect().top + window.pageYOffset - parseInt(event.currentTarget.dataset.scrollOffset);
      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      })
    })
  }
}

const initConsent = function () {
  var cookieconsent = initCookieConsent();
  cookieconsent.run({
    languages: {
      'pt': {
        consent_modal: {
          title: 'Sobre os cookies que utilizamos...',
          description: 'Neste site utilizamos cookies para melhorar a sua experiência de navegação, e para nos ajudar a conhecer melhor os padrões de navegação de quem nos visita. Esta informação permite-nos melhorar o conteúdo que apresentamos e apresentar uma melhor experiência de utilização.',
          primary_btn: {
            text: 'Aceitar',
            role: 'accept_all'      // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Definições',
            role: 'settings'        // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: 'Definições de cookies',
          save_settings_btn: 'Guardar e fechar',
          accept_all_btn: 'Aceitar todos',
          cookie_table_headers: [
              {col1: 'Name'},
              {col2: 'Description'}
          ],
          blocks: [
            {
              title: 'Cookies utilizados em www.exclusivamente.pt',
              description: 'Utilizamos cookies para dois propósitos diferentes: para funcionamento base da aplicação, e para monitorização analítica de padrões de navegação. Embora os últimos sejam opcionais, os primeiros são essenciais ao funcionamento da aplicação que sustenta este site.'
            }, {
              title: 'Essenciais ao funcionamento do site',
              description: '',
              toggle: {
                value: 'essential',
                enabled: true,
                readonly: true
              }
            }, {
              title: 'Monitorização analítica',
              description: '',
              toggle: {
                value: 'analytics',
                enabled: true,
                readonly: false
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'Google Analytics',
                  is_regex: true
                }
              ]
            }
          ]
        }
      }
    },
    current_lang: 'pt',
    autoclear_cookies: true,
    page_scripts: true,
    remove_cookie_tables: true,
    gui_options: {
      consent_modal: {
        layout: 'bar',               // box/cloud/bar
        position: 'bottom center',     // bottom/middle/top + left/right/center
        transition: 'slide',           // zoom/slide
        swap_buttons: false            // enable to invert buttons
      },
      settings_modal: {
        layout: 'bar',                 // box/bar
        position: 'right',           // left/right
        transition: 'slide'            // zoom/slide
      }
    },
    onFirstAction: function(user_preferences, cookie){
      if(cookie.categories.includes("analytics")){
        window.dataLayer = window.dataLayer || []
        function gtag(){dataLayer.push(arguments)}
        gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });  
      } else {
        window.dataLayer = window.dataLayer || []
        function gtag(){dataLayer.push(arguments)}
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });
      }
    },
  });
}

const initGoogleAnalytics = function (event) {
  window.dataLayer = window.dataLayer || []
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date())
  gtag('config', 'G-WR7G4JJVP3', {'page_location': event.data.url})
}

const initFormValidation = function() {
  var forms = document.getElementsByClassName('needs-validation');
  // Loop over them and prevent submission
  var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });
}

const initAppointmentPreferredTime = function() {
  let options = document.querySelectorAll('.preferred-time-option');
  options.forEach((option) => {
    option.addEventListener('click', (event) => {
      event.preventDefault();
      options.forEach((o) => o.classList.remove('active'));
      option.classList.add('active');
      document.getElementById('appointment_preferred_time').value = option.dataset.selectionValue;
    });
  });
}

const initGoTo = function () {
  new HSGoTo('.js-go-to')
}

document.addEventListener("DOMContentLoaded", function(event) {
});

document.addEventListener("turbolinks:load", (event) => {
  initGoogleAnalytics(event);
  initConsent();
  initTypedComponents();
  initTooltips();
  initAnchorLinks();
  initFormValidation();
  initAppointmentPreferredTime();
  initGoTo();

  HSCore.components.HSTomSelect.init('.js-select')

  new HSHeader('#header').init();

  new HSMegaMenu('.js-mega-menu', {
    eventType: 'click',
    desktop: {
      position: 'left'
    }
  })
  feather.replace();
})